import React from 'react';
import PropTypes from 'prop-types';
import { useRenderContext } from '../../pages/home/context';

const ProductsCarouselWrapper = (RecommendationsBase) => {
  const ProductsCarouselBase = (props) => {
    const { currency, restClient, theme, features } = useRenderContext();
    const { i18n, tabs } = props;
    const shippingTitle = i18n.gettext('Envío gratis');
    const ftNewComponentsV2 = features?.includes('ft-new-components-v2');

    return (tabs.length > 0)
      && (
      <div className="mshops-recommendations-wrapper">
        <RecommendationsBase
          {...props}
          metadata={currency}
          restClient={restClient}
          type="recommendations"
          shippingTitle={shippingTitle}
          layoutTheme={theme}
          ftNewComponentsV2={ftNewComponentsV2}
        />
      </div>
      );
  };

  ProductsCarouselBase.propTypes = {
    i18n: PropTypes.shape({ gettext: PropTypes.func }).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        permalink: PropTypes.string.isRequired,
        picture: PropTypes.object.isRequired,
        price: PropTypes.object.isRequired,
        shipping: PropTypes.object.isRequired,
      })),
    })).isRequired,
  };

  return ProductsCarouselBase;
};

export default ProductsCarouselWrapper;
